import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from "react-router-dom";
import {
  Button,
  Typography,
  Dropdown,
  Menu,
  Modal,
  Form,
  Space,
  Input,
  message,
  Image,
  Tooltip,
} from 'antd';
import {
  ArrowLeftOutlined,
  CheckOutlined,
  SettingOutlined,
  DownOutlined,
  InteractionOutlined,
  PlusSquareOutlined,
  UserAddOutlined,
  RightOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import EditIcon from'../common/images/icons/edit-icon.png';
import { setDefaultWorkspace, updateWorkspace, openCreateWorkspaceModal } from '../store/account';
import { quitWorkspaceApi } from '../api/ServerApi';

const WorkspacesManagement = (props:any) => {
  const { t } = useTranslation();
  const [modalItem, setModalItem] = useState()
  const [form] = Form.useForm()
  const history = useHistory();
  const have_own_workspace = (props.workspaces || []).filter((workspace) => workspace.member_type==="OWNER").length > 0

  const handleChangeWorkspace = (id) => {
    if (id !== props.current_workspace?.id && !!props.workspaces?.find(w => w.id === id)) {
      props.setDefaultWorkspace({
        id: id,
        success: () => {
          window.location.href = '/'
        },
        fail: () => {
          message.error( t('workspace.tips.set_default', {status: t('common.fail')}) )
        }
      })
    }
  }

  const handleConfigWorkspace = (e, workspace) => {
    e.stopPropagation()
    form.setFieldsValue(workspace)
    setModalItem(workspace)
  }

  const handleCloseModal = () => { setModalItem(null) }

  const handleUpdateWorkspace = (data) => {
    props.updateWorkspace({
      id: modalItem.id,
      name: data.name,
      success: () => {
        message.success( t('workspace.tips.update', {status: t('common.success')}) )
        setModalItem(null)
      },
      fail: () => {
        message.error( t('workspace.tips.update', {status: t('common.fail')}) )
      }
    })
  }

  const UpdateWorkspaceModal = () => (
    <Modal
      title={t("workspace.change")}
      centered
      open={!!modalItem}
      onCancel={handleCloseModal}
      footer={null}
      zIndex={2000}
    >
      {
        !!modalItem && <Form name="update workspace" form={form} onFinish={handleUpdateWorkspace}>
          <Form.Item
            label={t('workspace.name')}
            name="name"
            rules={[{ required: true, max:20}]}
          >
            <Input showCount maxLength={20} />
          </Form.Item>
          <Space size={15} style={{display: 'flex', justifyContent: 'flex-end', marginTop: 30}}>
            <Button onClick={handleCloseModal}>
              {t('common.cancel')}
            </Button>
            <Button type="primary" htmlType="submit">
              {t('common.save')}
            </Button>
          </Space>
        </Form>
      }
    </Modal>
  )

  const handleManageBack = () => {
    document.getElementById('header-workspace-manage-switch').style.transform = 'translate(0, 0)';
  }

  const handleGoSwitch = () => {
    document.getElementById('header-workspace-manage-switch').style.transform = 'translate(-100%, 0)';
  }

  const handleGoWorkspaceManage = () => {
    history.push({
      pathname: "/workspace_manage",
    })
  }

  const handleGoMemberManage = () => {
    history.push({
      pathname: "/workspace_manage",
      state: { tab: 'workspace_user_manage' }
    })
  }

  const MenuComp = () => {
    const [hoveredWorkspace, setHoveredWorkspace] = useState(undefined);

    const handleMouseOver = (workspace) => {
      setHoveredWorkspace(workspace)
    };

    const handleMouseOut = (event) => {
      if (event.currentTarget.contains(event.relatedTarget)) {
        return;
      }
      setHoveredWorkspace(undefined)
    };

    return (
      <Menu className='header-workspace-manage' style={{width: "18em", overflow: 'hidden'}}>
        <div className='current-workspace d-flex align-items-center justify-content-between'>
          <Typography.Text ellipsis={true} style={{
            paddingRight: props.current_workspace.member_type==="OWNER" ? '2.5em' : '0'
          }}>
            { props.current_workspace?.name }
          </Typography.Text>
          {
            props.current_workspace.member_type==="OWNER" && <Button
              type='text'
              size='small'
              onClick={(e) => handleConfigWorkspace(e, props.current_workspace)}
              icon={
                <Image
                  src={EditIcon}
                  preview={false}
                  style={{
                    width: 15,
                    height: 15,
                  }}
                />
              }
            />
          }
        </div>
        <Menu.Divider />
        <Menu.Item
          key={'settings'}
          disabled={props.current_workspace.member_type==="MEMBER"}
          icon={<SettingOutlined/>}
          onClick={handleGoWorkspaceManage}>
          {t('nav.all_workspace_manage')}
        </Menu.Item>
        <Menu.Item
          key={'member_manage'}
          disabled={props.current_workspace.member_type==="MEMBER"}
          icon={<UserAddOutlined />}
          onClick={handleGoMemberManage}>
          {t('workspace_user_manage.action.add')}
        </Menu.Item>
        <Menu.Divider />
        {
          (
            props.account_info?.enterprise_mode !== true ||
            !!props.account_info?.is_superuser
          ) && <Menu.Item
            key={'create'}
            disabled={!!have_own_workspace && props.account_info?.is_superuser===false}
            icon={<PlusSquareOutlined />}
            onClick={props.openCreateWorkspaceModal}>
            {t('workspace.new')}
          </Menu.Item>
        }
        <div className='ant-dropdown-menu-item' onClick={handleGoSwitch}>
          <InteractionOutlined className='ant-dropdown-menu-item-icon' />
          <span style={{flex: 1}}>{t('workspace.switch')}</span>
          <RightOutlined style={{fontSize: '0.8em', marginTop: '0.2em'}} />
        </div>
        <div id='header-workspace-manage-switch' className='workspace-manage-list'>
          <div className='header'>
            <Button icon={<ArrowLeftOutlined />} type='text' onClick={handleManageBack} />
              {t('workspace.switch')}
            <div style={{width: '2em'}}></div>
          </div>
          <div style={{flex: 1, overflowY: 'auto', padding: "0 10px"}}>
            {
              !!props.workspaces && props.workspaces.map((workspace, index) => (
                <div
                  className='item'
                  style={{display: 'flex', paddingRight: workspace.id === props.current_workspace?.id ? '30px' : '10px'}}
                  onMouseEnter={() => handleMouseOver(workspace)}
                  onMouseLeave={(e) => handleMouseOut(e)}>
                  <Typography.Text style={{ flex: 1 }} strong ellipsis={true} type={
                    workspace.id === props.current_workspace?.id ? "default" : "secondary"
                  } onClick={() => { handleChangeWorkspace(workspace.id) }}>
                    {workspace.name}
                  </Typography.Text>
                  {
                    workspace.id === props.current_workspace?.id && <CheckOutlined style={{
                      position: 'absolute',
                      right: 5,
                      top: 13,
                      color: '#52c41a'
                    }} />
                  }
                  {
                    workspace.id !== props.current_workspace?.id && workspace === hoveredWorkspace && <Dropdown dropdownRender={
                      () => <Menu
                        items={[
                          {
                            label: <Tooltip title={workspace.member_type === "OWNER" ? t("workspace.cannot_quit") : ""} key="leftButton">{t('workspace.quit')}</Tooltip>,
                            key: 'quit',
                            disabled: workspace.member_type === "OWNER",
                            onClick: () => handleQuitWorkspace(workspace)
                          },
                        ]}
                      />
                    }
                      placement="bottomLeft">
                      <a onClick={(e) => e.stopPropagation()}>
                        <EllipsisOutlined />
                      </a>
                    </Dropdown>
                  }
                </div>
              ))
            }
          </div>
        </div>
      </Menu>
    )
  }

  const handleQuitWorkspace = (workspace) => {
    Modal.confirm({
      title: t("workspace.quit"),
      content: <Trans
        i18nKey="workspace.confirm_quit_content"
        values={{
          workspace: workspace.name,
        }}
        components={{
          p: <p />,
          b: <b style={{fontWeight: 'bold'}} />,
        }}
      />,
      cancelText: t('common.cancel'),
      okText: t('workspace.confirm_quit'),
      onOk() {
        quitWorkspaceApi({
          workspace_id: workspace.id,
        }).then((res) => {
          if (res.status && !!res.data.id) {
            message.success(
              t("workspace.tips.quit", {
                status: t("common.success"),
              }),
            );
            window.location.href = '/'
          } else {
            message.error(
              t("workspace.tips.quit", {
                status: t("common.fail"),
              }),
            );
          }
        });
      }
    });
  }

  return (
      <>
        <UpdateWorkspaceModal />
        <Dropdown overlay = {<MenuComp/>} placement="bottomLeft" destroyPopupOnHide={true} {...props}>
          <Button type='text' className='header-button'>
            <InteractionOutlined className='horizontal-hidden' />
            <span className='vertical-hidden'>{ props.current_workspace?.name }</span>
            <DownOutlined className='vertical-hidden' />
          </Button>
        </Dropdown>
      </>
  )
}

const mapStateToProps = (store) => ({
  account_info: store.account.account_info,
  token: store.account.token,
  workspaces: store.account.workspaces,
  current_workspace: store.account.current_workspace,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  setDefaultWorkspace,
  updateWorkspace,
  openCreateWorkspaceModal,
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(WorkspacesManagement);
