import {useEffect, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import ReactDOM from 'react-dom';

import {message, Spin, Modal, Button, Dropdown, Menu} from 'antd';
import {FullscreenOutlined, FullscreenExitOutlined} from '@ant-design/icons';

import {LuckysheetOption, LuckysheetOperator} from "../../interfaces/luckysheet";
import {DataFrame} from "../../interfaces/dataFrame";
import { getDataList, getDf, updateDf } from '../../api/AppApi'
import ExportSheetFromServerButton, {ExportSheetType} from './ExportSheetFromServerButton';
import { FinalStatus, ProcessStatus } from '../../interfaces/processStatus';
import ShareInstanceModal from '../../components/ShareInstanceModal';
import { RunInstance } from '../../interfaces/runInstance';
import { RunInstanceMemberRole } from '../../interfaces/runInstanceMember';
import DefaultSheetOption from '../../config/DefaultSheetOption';
import useWindowSize from '../../utils/useWindowSize';

import { getConfig } from "../../config/config";
const config = getConfig();

type Props = {
    api: any;
    tab: string;
    run_token: string;
    style?: {};
    sheetOption?: LuckysheetOption;
    active: boolean;
    loadCallback?: any;
    is_locked?: boolean;
    solving_status: ProcessStatus;
    instance: RunInstance;
    addActions?: JSX.Element;
}

const InstanceSheet = (props:Props) => {
    const windowSize = useWindowSize({id: `luckysheet-view-${props.run_token}`});

    const { t, i18n } = useTranslation();
    const [ loading, setLoading ] = useState(true);
    const [ haveChanged, setHaveChanged ] = useState(false);
    const sheetRef:any = useRef();
    const sheetChangeDataRef:any = useRef({});
    const orginsheetDataRef:any = useRef({});
    const enableAddRow = props.tab === 'input_view';

    const calHaveChange = () => {
        return Object.values(sheetChangeDataRef.current).filter(
            (item:any) => {
                return !!item && Object.values(item).filter(
                    (data:any) => !!data
                ).length > 0
            }
        ).length > 0
    }

    useEffect(() => {
        try {
            window.luckysheet.resize();
        } catch(e) {
            console.log(e);
        }
    }, [windowSize])

    const applyChange = async () => {
        try {
            window.luckysheet.saveEditingCell();
        } catch (err) {}
        Modal.confirm({
            title: t('instance.confirm.save_update'),
            onOk: async () => {
                const formatChangeData = Object.entries(sheetChangeDataRef.current).map((sheet: any) => {
                    const sheet_index = sheet[0];
                    const changeData = sheet[1];
                    const originData = orginsheetDataRef.current ? orginsheetDataRef.current[sheet_index] : [];
                    if(!!changeData && !!originData) {
                        const sumChange = Object.entries(changeData).reduce((ret: any, item: [string, SheetChangeDataType]) => {
                            ret.data.push(item[1])
                            ret.index.push(JSON.parse(item[0]))
                            return ret;
                        }, { data: [], index: []})
                        return {
                            df_name: originData.df_name,
                            sheet_index: sheet_index,
                            change_data: {
                                data: sumChange.data,
                                index: sumChange.index,
                                meta: {
                                    ...originData.meta,
                                    data_warning: []
                                }
                            }
                        };
                    } else {
                        return null;
                    }
                }).filter((sheet: any) => !!sheet);
                for(let sheetIndex in formatChangeData) {
                    const sheet = formatChangeData[sheetIndex];
                    if(!!sheet) {
                        const response = await updateDf(props.api, {
                            instance_id: props.run_token,
                            df_name: sheet!.df_name,
                            df_dict: sheet!.change_data,
                        })
                        const CurrentSheetName = (
                            i18n.resolvedLanguage === 'en' ? sheet.change_data.meta.name?.en :
                                sheet.change_data.meta.name?.zh
                        ) || sheet.change_data.meta.name;
                        if (response.status && response.data.update_success) {
                            message.success( `${CurrentSheetName} - ${t('sheet.tip.update_success')}` )
                            delete sheetChangeDataRef.current[sheet.sheet_index]
                            checkSheetIndexNotEditable(sheet.sheet_index);
                            resetSheetBorderInfoConfig(sheet.sheet_index);
                        } else {
                            message.error( `${CurrentSheetName} - ${t('sheet.tip.update_fail')}` )
                        }
                    }
                }
                setHaveChanged(calHaveChange());
            }
        })
    }

    const calSheetBorderInfoConfig = (num_index: number, num_column: number, num_row: number) => {
        return[{
            "rangeType": "range",
            "borderType": "border-all",
            "color": "#000",
            "style": "1",
            "range": [
                {
                    "row": [0, num_row],
                    "column": [0, num_column - 1]
                }
            ]
        },
            {
                "rangeType": "range",
                "borderType": "border-outside",
                "color": "#000",
                "style": "8",
                "range": [
                    {
                        "row": [0, num_row],
                        "column": [0, num_column - 1]
                    }
                ]
            },
            {
                "rangeType": "range",
                "borderType": "border-outside",
                "color": "#000",
                "style": "8",
                "range": [
                    {
                        "row": [0, 0],
                        "column": [0, num_column - 1]
                    }
                ]
            },
            {
                "rangeType": "range",
                "borderType": "border-outside",
                "color": "#000",
                "style": "8",
                "range": [
                    {
                        "row": [0, num_row],
                        "column": [0, num_index - 1]
                    }
                ]
            }
        ]
     }

    const resetSheetBorderInfoConfig = (sheet_index: string) => {
        // setConfig
        const sheet = window.luckysheet.getSheet({
            index: sheet_index
        });
        const tableMeta = (orginsheetDataRef.current || {})[sheet_index]?.meta;
        if(!!sheet && !!tableMeta) {
            let config = sheet.config;
            config.borderInfo = calSheetBorderInfoConfig(
                tableMeta?.num_index_column,
                tableMeta?.num_column,
                sheet.data.length
            );
            window.luckysheet.setConfig(config, { order: sheet_index.order })
        }
    }

    const checkSheetIndexNotEditable = (sheet_index: string) => {
        const sheet = window.luckysheet.getSheet({
            index: sheet_index
        });
        const numIndexColumn = (orginsheetDataRef.current || {})[sheet_index]?.meta?.num_index_column;
        if(!!sheet && !!numIndexColumn) {
            for(let c = 0; c < numIndexColumn; c++) {
                for(let r = 1, len = sheet?.data.length || 0; r < len; r ++) {
                    let cellData = sheet?.data[r][c];
                    if(!!cellData && !cellData.notEditable) {
                        window.luckysheet.setCellValue(
                            r, c,
                            {
                              ...cellData,
                              notEditable: true
                            }
                        );
                    }
                }
            }
        }
    }

    const addFullScreenBtn = () => {
        const toolHeader = document.getElementById(
            "luckysheet-wa-editor"
        );
        if (toolHeader) {
            let btn = document.createElement("div");
            btn.id = "luckysheet-full-screen";
            btn.className =
                "luckysheet-toolbar-menu-button luckysheet-inline-block";
            btn.dataset.tips = t("common.full_screen");
            toolHeader.append(btn);
            btn = document.createElement("div");
            btn.id = "luckysheet-exit-full-screen";
            btn.className =
                "luckysheet-toolbar-menu-button luckysheet-inline-block";
            btn.dataset.tips = t("common.exit_full_screen");
            toolHeader.append(btn);
            ReactDOM.render(
                <Button
                    size="small"
                    type="text"
                    style={{ lineHeight: 1 }}
                    icon={<FullscreenOutlined />}
                    onClick={() => {
                        try {
                            document.getElementById("run_instances_view_content").classList.add("full-screen");
                        }catch(e){}
                    }}
                />,
                document.getElementById('luckysheet-full-screen')
            );
            ReactDOM.render(
                <Button
                    size="small"
                    type="text"
                    style={{ lineHeight: 1 }}
                    icon={<FullscreenExitOutlined />}
                    onClick={() => {
                        try {
                            document.getElementById("run_instances_view_content").classList.remove("full-screen");
                        }catch(e){}
                    }}
                />,
                document.getElementById('luckysheet-exit-full-screen')
            );
        }
    }

    const addDecimalFormatBtn = () => {
        const toolHeader = document.getElementById(
            "luckysheet-wa-editor"
        );
        if (toolHeader) {
            const btn = document.createElement("div");
            btn.id = "luckysheet-decimal-format";
            btn.className =
                "luckysheet-toolbar-menu-button luckysheet-inline-block";
            btn.dataset.tips = t("sheet.decimal_format");
            toolHeader.append(btn);
            ReactDOM.render(
                <Dropdown
                    trigger={["click"]}
                    dropdownRender={
                        () => <Menu
                            className='luckysheet-cols-menu-style'
                            style={{minWidth: "150px"}}
                            items={new Array(10).fill(null).map((item, index) => ({
                                key: index,
                                label: index,
                            }))}
                            onClick={(e) => {
                                window.luckysheet.setRangeDecimalFormat(parseInt(e?.key) || 2);
                            }}
                        />
                    }
                >
                  <Button
                    size="small"
                    type="text"
                    style={{ lineHeight: 1 }}
                  >
                    <div className="luckysheet-icon-img-container iconfont luckysheet-iconfont-zengjiaxiaoshuwei">
                    </div>
                  </Button>
                </Dropdown>,
                document.getElementById('luckysheet-decimal-format')
            );
        }
    }

    useEffect(() => {
        const controller = new AbortController();
        setLoading(true)
        if(sheetRef.current) {
            if((window.luckysheet.getAllSheets() || []).length > 0) {
                window.luckysheet.destroy();
            }
            orginsheetDataRef.current = {};
            getDataList(props.api, {
                list: props.tab === 'input_view' ? '/input_list' : '/output_list'
            }, { signal: controller.signal }).then((list_res) => {
                    if(list_res.status && list_res.data.length > 0) {
                        getDf(props.api, {
                            instance_id: props.run_token,
                            df_name: list_res.data[0].df_name
                        }, { signal: controller.signal }).then((res) => {
                            if(res.status) {
                                // props.loadCallback && props.loadCallback(props.tab)
                                sheetChangeDataRef.current = {}
                                orginsheetDataRef.current = {}
                                const celldata = formatCellData(list_res.data[0].df_name, res.data)
                                orginsheetDataRef.current[list_res.data[0].df_name] = {...res.data, df_name: list_res.data[0].df_name};
                                const FirstSheetName = (
                                    i18n.resolvedLanguage === 'en' ? res.data.meta.name?.en :
                                        res.data.meta.name?.zh
                                ) || res.data.meta.name;
                                var options = {
                                    ...DefaultSheetOption,
                                    ...props.sheetOption,
                                    allowUpdate: props.tab === 'input_view',
                                    enableAddRow: enableAddRow,
                                    lang: i18n.resolvedLanguage,
                                    container: `luckysheet-view-${props.run_token}`,
                                    gridKey: `luckysheet-view-${props.run_token}`,
                                    showtoolbarConfig: {...DefaultSheetOption.showtoolbarConfig, ...props.sheetOption?.showtoolbarConfig},
                                    cellRightClickConfig: {...DefaultSheetOption.cellRightClickConfig, ...props.sheetOption?.cellRightClickConfig},
                                    showsheetbarConfig: {...DefaultSheetOption.showsheetbarConfig, ...props.sheetOption?.showsheetbarConfig},
                                    sheetRightClickConfig: {...DefaultSheetOption.sheetRightClickConfig, ...props.sheetOption?.sheetRightClickConfig},
                                    data: list_res.data.map((sheet, index) => ({
                                        order: index,
                                        index: sheet.df_name,
                                        status: index === 0 ? 1 : 0,
                                        index_key: sheet.df_name,
                                        row: 0,
                                        column: 0,
                                        frozen: { type: 'row'},
                                        ...(index === 0 ? {
                                            celldata: celldata.celldata,
                                            config: celldata.config,
                                            dataVerification: celldata.dataVerification,
                                            name: FirstSheetName,
                                        } : { name: `⌛️  ${(
                                            i18n.resolvedLanguage === 'en' ? sheet.name?.en :
                                                sheet.name?.zh
                                        ) || sheet.df_name}`, })
                                    })),
                                    initloadSheetFunc: (setData) => {
                                        list_res.data.forEach((sheet, index) => {
                                            if(index !== 0) {
                                                getDf(props.api, {
                                                    instance_id: props.run_token,
                                                    df_name: sheet.df_name
                                                }, { signal: controller.signal }).then((res) => {
                                                    if(res.status) {
                                                        const CurrentSheetName = (
                                                            i18n.resolvedLanguage === 'en' ? res.data.meta.name?.en :
                                                                res.data.meta.name?.zh
                                                        ) || res.data.meta.name;
                                                        const currentSheet = luckysheet.getSheet({ index: sheet.df_name });
                                                        !!currentSheet && window.luckysheet.setSheetName(
                                                            CurrentSheetName,
                                                            { order: currentSheet.order}
                                                        );
                                                        orginsheetDataRef.current[sheet.df_name] = {...res.data, df_name: sheet.df_name};
                                                        const sheet_cell = formatCellData(sheet.df_name, res.data);
                                                        setData(sheet.df_name, sheet_cell.celldata);
                                                        !!currentSheet && window.luckysheet.setConfig(
                                                            sheet_cell.config,
                                                            {order: currentSheet.order}
                                                        )
                                                        initDataVerification(sheet.df_name, res.data);
                                                    }
                                                }).catch((error) => console.log(error))
                                            }
                                        })
                                    },
                                    hook: {
                                        workbookCreateAfter: async () => {
                                            if(enableAddRow) {
                                                const addRowCtl = document.getElementById(
                                                    "luckysheet-bottom-add-row"
                                                );
                                                if (addRowCtl) {
                                                    addRowCtl.innerHTML =
                                                        "<div style='float: left; font-size: 1.5em;line-height: 1;margin-top:-0.1em;margin-right:0.3em;'>+</div> " +
                                                        t("sheet.add_row");
                                                }
                                            }
                                            addDecimalFormatBtn();
                                            addFullScreenBtn();
                                            window.luckysheet.setRangeShow("A1", {
                                                show: false,
                                            });
                                            initDataVerification(list_res.data[0].df_name, res.data);
                                            setLoading(false)
                                        },
                                        updated: function (operate: LuckysheetOperator) {
                                            if (props.tab === 'input_view' && [
                                                    'updateDataVerificationOfCheckbox',
                                                    'datachange'
                                                ].includes(operate.type)) {
                                                const tableMeta = orginsheetDataRef.current[operate.sheetIndex]?.meta;
                                                if(!tableMeta) {
                                                    return;
                                                }
                                                if(!!operate?.SortChange) {
                                                    const currentSheet = luckysheet.getSheet({ index: operate.sheetIndex });
                                                    tableMeta?.columns_type?.forEach((fieldType, index) => {
                                                        if(fieldType === 'boolean') {
                                                            window.luckysheet.setDataVerification(
                                                                {
                                                                    type: "checkbox",
                                                                    value1: true,
                                                                    value2: false,
                                                                    prohibitInput: true,
                                                                },
                                                                {
                                                                    order: currentSheet.order,
                                                                    range: {
                                                                        row: [1, tableMeta?.num_row],
                                                                        column: [index, index]
                                                                    }
                                                                }
                                                            )
                                                        }
                                                    })
                                                    return;
                                                }
                                                const numIndexColumn = tableMeta?.num_index_column;
                                                const numColumn = tableMeta?.num_column;
                                                const ranges = operate.range instanceof Array ? operate.range : [operate.range];
                                                for (let i in ranges) {
                                                    const start_col = ranges[i].column[0]
                                                    const end_col = ranges[i].column[1]
                                                    const start_row = ranges[i].row[0]
                                                    const end_row = ranges[i].row[1]
                                                    for (let j = start_row; j <= end_row; j++) {
                                                        let row_index_data = (operate.curdata || operate.curData || [])[j]?.slice(0,numIndexColumn)?.map(
                                                            item => (item?.v !== null && item?.v !== undefined) ? item?.v : item
                                                        );
                                                        if(!row_index_data){
                                                            continue;
                                                        }
                                                        let row_index = JSON.stringify( row_index_data );
                                                        for (let k = start_col; k <= end_col; k++) {
                                                            const newData = (operate.curdata || operate.curData || [])[j][k];
                                                            const originData = operate.data[j][k] || {};
                                                            if(newData?.v !== originData?.v) {
                                                                try {
                                                                    if(k < numIndexColumn) {
                                                                        row_index_data[k] = originData?.v || null;
                                                                        let origin_row_index = JSON.stringify( row_index_data );
                                                                        if(!!sheetChangeDataRef.current[operate.sheetIndex][origin_row_index]) {
                                                                            if(!sheetChangeDataRef.current[operate.sheetIndex][row_index]) {
                                                                                sheetChangeDataRef.current[operate.sheetIndex][row_index] = sheetChangeDataRef.current[operate.sheetIndex][origin_row_index];
                                                                            }
                                                                            delete sheetChangeDataRef.current[operate.sheetIndex][origin_row_index];
                                                                        }
                                                                    }
                                                                } catch(e) {}
                                                                if(!sheetChangeDataRef.current[operate.sheetIndex]) {
                                                                    sheetChangeDataRef.current[operate.sheetIndex] = {}
                                                                }
                                                                if(!sheetChangeDataRef.current[operate.sheetIndex][row_index]) {
                                                                    sheetChangeDataRef.current[operate.sheetIndex][row_index] = new Array(
                                                                        numColumn - numIndexColumn
                                                                    ).fill(null);
                                                                }
                                                                if(k >= numIndexColumn) {
                                                                    sheetChangeDataRef.current[operate.sheetIndex][row_index][k-numIndexColumn] = (
                                                                        (newData?.v !== null && newData?.v !== undefined) ||
                                                                        Object.prototype.toString.call(newData) === '[object Object]'
                                                                    ) ? newData?.v : newData
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                                setHaveChanged(calHaveChange())
                                            }
                                        }
                                    }
                                }
                                try {
                                    window.luckysheet.create(options);
                                } catch {}
                            }
                        }).catch((error) => console.log(error))
                    }
            }).catch((error) => console.log(error))
        }
        return () => {
            controller.abort();
            window.luckysheet.destroy();
        }
    // eslint-disable-next-line
    }, [
        props.run_token,
        props.tab,
        i18n.resolvedLanguage,
        props.sheetOption,
        props.api
    ]);

    const calColumnlen = (str: string) => {
        return (str.match(/[\u4E00-\u9FA5]/g) || []).length * 7 + str.length * 9
    }

    const initDataVerification = (index, data: DataFrame) => {
        if(props.tab === 'output_view') {
            return;
        }
        const currentSheet = luckysheet.getSheet({ index: index });
        if(!currentSheet) { return; }

        Object.entries(currentSheet.dataVerification || {}).filter(
            item => item[1].columnType === "BOOLEAN"
        ).forEach(item => {
            const cellIndex = item[0].split('_');
            window.luckysheet.setDataVerification(
                {
                    type: "checkbox",
                    value1: true,
                    value2: false,
                    prohibitInput: true,
                },
                {
                    order: currentSheet.order,
                    range: {
                        row: [cellIndex[0], cellIndex[0]],
                        column: [cellIndex[1], cellIndex[1]]
                    }
                }
            )
        })
        const booleanColumns = data.meta.columns_type.map(
            (item: string, index: int) => [index, item]
        ).filter(
            item => item[1] === 'boolean'
        )
        data.meta.columns_type.forEach((item: string, index: int) => {
            if(item === 'boolean') {
                window.luckysheet.setDataVerification(
                    {
                        type: "checkbox",
                        value1: true,
                        value2: false,
                        prohibitInput: true,
                    },
                    {
                        order: currentSheet.order,
                        range: { row: [1, data.meta.num_row], column: [index, index] }
                    }
                )
            } else if(item === 'number' || item === 'integer') {
                window.luckysheet.setDataVerification(
                    {
                        type: "validity",
                        type2: "regular",
                        value1: /^(-)*\d+(\.\d+)?$/,
                        value2: t('instance.tip.not_a_number'),
                        prohibitInput: true,
                    },
                    {
                        order: currentSheet.order,
                        range: { row: [1, data.meta.num_row], column: [index, index] }
                    }
                )
            }
        })

        if(booleanColumns.length > 0) {
            booleanColumns.forEach(item => {
                window.luckysheet.setDataVerification(
                    {
                        type: "checkbox",
                        value1: true,
                        value2: false,
                        prohibitInput: true,
                    },
                    {
                        order: currentSheet.order,
                        range: { row: [1, data.meta.num_row], column: [item[0], item[0]] }
                    }
                )
            })
        }
    }

    const reduce_row_type_dataVerifications = (dataVerifications, rowTypes, r) => {
        if(!!rowTypes) {
            Object.entries(rowTypes).forEach(item => {
                if(item[1] === 'INT') {
                    dataVerifications[`${r+1}_${item[0]}`] = {
                        type: "validity",
                        type2: "regular",
                        value1: /^(-)*\d+(\.0+)?$/,
                        value2: t('instance.tip.not_a_number'),
                        prohibitInput: true,
                        columnType: item[1]
                    };
                } else if(item[1] === 'FLOAT') {
                    dataVerifications[`${r+1}_${item[0]}`] = {
                        type: "validity",
                        type2: "regular",
                        value1: /^(-)*\d+(\.\d+)?$/,
                        value2: t('instance.tip.not_a_number'),
                        prohibitInput: true,
                        columnType: item[1]
                    };

                } else if(item[1] === 'BOOLEAN') {
                    dataVerifications[`${r+1}_${item[0]}`] = {
                        type: "checkbox",
                        value1: true,
                        value2: false,
                        prohibitInput: true,
                        columnType: item[1]
                    };

                } else if(item[1] !== 'STRING') {
                    try{
                        if(item[1][0] === 'ENUM') {
                            dataVerifications[`${r+1}_${item[0]}`] = {
                                type: "dropdown",
                                value1: item[1][1].join(","),
                                prohibitInput: true,
                                columnType: item[1][0]
                            };
                        }
                    }catch(e) {}
                }
            })
        }
    }

    const formatCellValue = (value: any, type: any) => {
        return value === 'None' ? null : (
            type === 'number' && !value ? 0 : value
        )
    }

    const formatCellData = (df_name: string, data: DataFrame) => {
        const rowsLocale = (data.meta.rows_locale || []).reduce((ret, item) => {
            ret[item.column] = item.locale || {}
            return ret;
        }, {});
        const rowsType = (data.meta.rows_type || []).reduce((ret, item) => {
            let keyColumnIndex = -1;
            try {
                keyColumnIndex = data.meta.columns.indexOf(item.key_columns[0])
            } catch(e) {}
            if(keyColumnIndex < 0) { return ret; }
            let valueColumnIndex = -1;
            try {
                valueColumnIndex = data.meta.columns.indexOf(item.value_column)
            } catch(e) {}
            if(valueColumnIndex < 0 || data.meta.columns_type[valueColumnIndex] !== 'string') { return ret; }
            if(!ret[keyColumnIndex]) {
                ret[keyColumnIndex] = {}
            }
            (Object.keys(item.type || {}) || []).forEach(value => {
                if(!ret[keyColumnIndex][value]){
                    ret[keyColumnIndex][value] = {}
                }
                ret[keyColumnIndex][value][valueColumnIndex] = item.type[value]
            });
            return ret;
        }, {});
        try {
            data.meta.data_warning = data.meta.data_warning?.reduce((ret, item) => {
                ret[item.column] = eval(item.function);
                return ret;
            }, {})
        } catch(e){}
        let ret = []
        let dataVerifications = {};
        var config: any = {}
        // header
        for (let c = 0; c < data.meta.columns.length; c++) {
            ret.push({
                r: 0, c: c, v:
                    {
                        v: ((data.meta.columns_locale || {})[
                            data.meta.columns[c]
                        ] || {})[i18n.resolvedLanguage] || data.meta.columns[c],
                        bl: 1,
                        notEditable: true,
                    }
            })
        }
        // index columns
        if (data.meta.num_index_column === 1) {
            for (let c = 0; c < data.meta.num_index_column; c++) {
                for (let r = 0; r < data.index.length; r++) {
                    let cellLocale = (rowsLocale[data.meta.columns[c]] || {})[data.index[r]] || {};
                    ret.push({
                        r: r + 1,
                        c: c,
                        v: {
                            v: formatCellValue(data.index[r], data.meta.columns_type[c]),
                            notEditable: true,
                            ...( data.meta.columns_type[c] === 'string' && !!cellLocale ? {
                                const_m: cellLocale[i18n.resolvedLanguage],
                                ct: {fa: "@", t: "s"},
                            } : {})
                        },
                    })
                    reduce_row_type_dataVerifications(
                        dataVerifications,
                        (rowsType[c] || {})[data.index[r]],
                        r
                    );
                }
            }
        } else {
            for (let c = 0; c < data.meta.num_index_column; c++) {
                for (let r = 0; r < data.index.length; r++) {
                    let cellLocale = (rowsLocale[data.meta.columns[c]] || {})[data.index[r][c]] || {};
                    ret.push({
                        r: r + 1,
                        c: c,
                        v: {
                            v: formatCellValue(data.index[r][c], data.meta.columns_type[c]),
                            notEditable: true,
                            ...( data.meta.columns_type[c] === 'string' && !!cellLocale ? {
                                const_m: cellLocale[i18n.resolvedLanguage],
                                ct: {fa: "@", t: "s"},
                            } : {})
                        },
                    })
                    reduce_row_type_dataVerifications(
                        dataVerifications,
                        (rowsType[c] || {})[data.index[r][c]],
                        r
                    );
                }
            }
        }
        // data columns
        let num_index_column = data.meta.num_index_column
        for (let c = num_index_column; c < data.meta.num_column; c++) {
            const columnType = data.meta.columns_type[c];
            const columnKey = data.meta.columns[c];
            let isLonlat = false;
            try {
                isLonlat = ['number', 'integer'].includes(columnType) && (
                    `${columnKey}`?.includes('latitude') || `${columnKey}`?.includes('longitude') ||
                    (data.meta?.columns_locale || {})[columnKey]?.zh?.includes('经度') ||
                    (data.meta?.columns_locale || {})[columnKey]?.zh?.includes('纬度') ||
                    (data.meta?.columns_locale || {})[columnKey]?.en?.includes('latitude') ||
                    (data.meta?.columns_locale || {})[columnKey]?.en?.includes('longitude') ||
                    (data.meta?.columns_locale || {})[columnKey]?.en?.includes('Latitude') ||
                    (data.meta?.columns_locale || {})[columnKey]?.en?.includes('Longitude')
                );
            } catch(e){}
            for (let r = 0; r < data.data.length; r++) {
                let value = dataVerifications[`${r+1}_${c}`]?.columnType === "BOOLEAN" ? (
                    !!data.data[r][c - num_index_column] &&
                    data.data[r][c - num_index_column] !== 'false' &&
                    data.data[r][c - num_index_column] !== '0'
                ) : formatCellValue(data.data[r][c - num_index_column], columnType);
                let needWarning = false;
                if(!!(data.meta.data_warning || {})[columnKey]) {
                    try {
                        needWarning = (data.meta.data_warning || {})[columnKey](value);
                    } catch(e){ console.log(e); }
                }
                ret.push({
                    r: r + 1,
                    c: c,
                    v: {
                        v: value,
                        notEditable: props.tab === 'output_view',
                        ct: isLonlat ? {
                            fa: "0.0000", t: "n"
                        } : columnType === 'number' ? {
                            fa: "0.00", t: "n"
                        } : columnType === 'integer' ?  {
                            fa: "0", t: "n"
                        } : {},
                        bg: needWarning ? '#FA8072' : "#ffffff"
                    },
                });
                reduce_row_type_dataVerifications(
                    dataVerifications,
                    (rowsType[c] || {})[data.data[r][c - num_index_column]],
                    r
                );
            }
        }
        if(ret.length > 1) {
            [ret[0],ret[ret.length - 1]] = [ret[ret.length - 1],ret[0]];
        }
        config.merge = {}
        config.borderInfo = calSheetBorderInfoConfig(
            data.meta.num_index_column,
            data.meta.num_column,
            data.index.length
        );
        try {
            config.columnlen = data.meta.columns.reduce((ret, item, index) => {
                let columnDataMaxLen = 0;
                if(index >= data.meta.num_index_column) {
                    for(let di = 0, len = data.data.length; di < len && di < 10000; di++) {
                        let columnlen = calColumnlen(`${data.data[di][index - data.meta.num_index_column]}`)
                        if(columnDataMaxLen < columnlen) {
                            columnDataMaxLen = columnlen;
                        }
                    }
                } else {
                    for(let di = 0, len = data.index.length; di < len && di < 10000; di++) {
                        let dRow = data.index[di];
                        let columnlen = calColumnlen(`${typeof dRow == "object" ? dRow[index] : dRow}`)
                        if(columnDataMaxLen < columnlen) {
                            columnDataMaxLen = columnlen;
                        }
                    }
                }
                ret[`${index}`] = Math.min(
                    Math.max(
                        calColumnlen(
                            ((data.meta.columns_locale || {})[item] || {})[
                                i18n.resolvedLanguage
                            ] || `${item}`
                        ),
                        columnDataMaxLen,
                        80
                    ),
                    500
                )
                return ret
            }, {})
        } catch(e) {
            config.columnlen = 80;
        }
        return {
            celldata: ret, config: config, dataVerification: dataVerifications
        }
    }

    return(
        <>
            <div className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center tab-action">
               <div className="btn-toolbar">
                    {
                        !!props.addActions && <props.addActions
                            disabled={haveChanged}
                        />
                    }
                    {
                        !!props.active && props.tab === 'input_view' && !!!props.is_locked && 
                        (props.instance?.role === RunInstanceMemberRole.ADMIN || props.instance?.owner.includes("client.auth")) && <button
                            disabled={!haveChanged || (!!props.solving_status && !FinalStatus.has(props.solving_status?.status))}
                            type="button"
                            className="btn btn-outline-primary"
                            style={{marginLeft: '0.5em'}}
                            onClick={applyChange}>
                            {t('instance.action.update_data')}
                        </button>
                    }
                    {
                        process.env.REACT_APP_MODE === 'simple-dev' && props.tab === 'input_view' && <button
                            disabled={!haveChanged}
                            type="button"
                            className="btn btn-outline-primary"
                            style={{marginLeft: '0.5em'}}
                            onClick={applyChange}>
                            {t('instance.action.update_data')}
                        </button>
                    }
                    <ExportSheetFromServerButton
                        api={props.api}
                        run_token={props.run_token}
                        type={props.tab === 'input_view' ? ExportSheetType.Input : ExportSheetType.Output}
                    />
                    {
                        process.env.REACT_APP_MODE !== 'simple-dev' && <ShareInstanceModal instance={props.instance}>
                            <button
                                type="button"
                                className="btn btn-primary"
                                style={{marginLeft: '0.5em'}}>
                                {t('instance.action.share')}
                            </button>
                        </ShareInstanceModal>
                    }
               </div>
           </div>
            <div style={{position: 'relative', background: "white"}}>
                <div
                    id={`luckysheet-view-${props.run_token}`}
                    className='instance-sheet-box'
                    ref={sheetRef}
                    style={{
                        margin: '0px',
                        padding: '0px',
                        position: 'relative',
                        width: '100%',
                        minHeight: `calc(100vh - 230px ${!!config.IsSimpleLayout ? '+ 60px' : ''} )`,
                        height: '400px',
                        left: '0px',
                        top: '0px',
                        ...props.style
                    }}>
                </div>
                {
                    loading && <div style={{
                        background: "white",
                        opacity: 0.6,
                        position: "absolute",
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 999
                    }}>
                        <Spin size="large">loading...</Spin>
                    </div>
                }
            </div>
        </>
    )
}

export default InstanceSheet;
