import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {Pagination, Typography, Input, Modal, Table, message, Dropdown, Menu, Image, Tooltip, Button, Tag, Popover, Badge} from 'antd';
import type { ColumnsType, TableProps } from 'antd/es/table';
import { EllipsisOutlined } from '@ant-design/icons';

import { RunInstance } from '../../interfaces/runInstance';
import {isRefreshNeeded} from '../../interfaces/processStatus';
import RunInstanceCreateButton from './RunInstanceCreateButton';
import RunInstanceCloneModal from './RunInstanceCloneModal';
import viewUtils from '../../utils/viewUtils';
import {getInstancesApi, updateInstanceApi} from '../../api/ServerApi';
import EditCell from '../../components/EditCell';
import { RunInstanceFormAction } from './RunInstanceForm';
import { RunInstanceMemberRole } from '../../interfaces/runInstanceMember';
import RunInstanceCompareModal from './RunInstanceCompareModal';
import ProcessStatusBtn from '../../components/ProcessStatusBtn';
import HeaderSearchIcon from '../../common/images/icons/header-search-icon.png';
import LockedIcon from'../../common/images/icons/locked-icon.png';

import { getConfig } from "../../config/config";
const config = getConfig();

interface RunInstanceListProps {
    current_workspace_id?: string;
    app_id: string;
    session_id?: string;
    active: boolean;
    page_size?: number;

    search?: boolean;
    archive?: boolean;
    unarchive?: boolean;
    clone: boolean;

    instance_create?: boolean;

    smallSize?: boolean;

    apps?: any;
    show_session?: boolean;
    show_list_actions?: boolean;
    need_refresh?: any;
};

const RunInstanceList = (props: RunInstanceListProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const instanceCloneModalRef:any = useRef();
    const instanceCompareModalRef:any = useRef();

    const [instances, setInstances] = useState({data: [], total: 0});
    const [filter, setFilter] = useState({
        page: 1,
        page_size: props.page_size || 10,
        search_key_words: '',
        order_by: 'created_at',
        sort: 'desc'
    });
    const [selectedInstances, setSelectedInstances] = useState<React.Key[]>([]);
    const handlePageChange = (page: number, page_size: number) => {
        setFilter(data => ({ ...data, page: page, page_size: page_size }));
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setFilter(
        data => ({ ...data, search_key_words: e.target.value, page: 1 })
    );

    const currentInstance = selectedInstances.length === 1 ? instances.data?.find(
        item => item.id === selectedInstances[0]
    ) : null;

    const handleTableChange: TableProps['onChange'] = (pagination, filters, sorter, extra) => {
        if(!!sorter.order) {
            setFilter(data => ({
                ...data,
                order_by: sorter.columnKey,
                sort: sorter.order === 'ascend' ? "asc" : "desc",
                page: 1
            }))
        } else {
            setFilter(data => ({
                ...data,
                order_by: 'created_at',
                sort: 'desc',
                page: 1
            }))
        }
    };

    useEffect(() => {
        setFilter(data => ({...data}));
    }, [
        props.need_refresh, setFilter
    ])

    useEffect(() => {
        getInstancesApi({
            app_id: props.app_id,
            workspace_id: props.current_workspace_id,
            page: filter.page,
            page_size: filter.page_size,
            active: props.active,
            ...( props.session_id ? { session_id: props.session_id } : null ),
            ...( filter.search_key_words ? { search_key_words: filter.search_key_words } : null ),
            ...( filter.order_by ? { order_by: filter.order_by } : null ),
            ...( filter.order_by === 'session_name' ? { order_by_session_name: filter.sort || 'desc' } : null ),
            ...( filter.order_by === 'created_at' ? { order_by_created_at: filter.sort || 'desc' } : null ),
            ...( filter.order_by === 'updated_at' ? { order_by_updated_at: filter.sort || 'desc' } : null ),
        }).then((res) => {
            if(res.status) {
                setInstances({
                    data: res.data || [],
                    total: res?.pagination?.total || 0
                });
            }
        });
    }, [filter, props]);

    useEffect(() => {
        setFilter(data => ({
            ...data,
            page: 1,
            page_size: props.page_size || 10,
            search_key_words: '',
            order_by: 'created_at',
            sort: 'desc'
        }))
    }, [props]);

    useEffect(() => {
        let intervalId:any = null;
        const shouldRefresh = (instances.data || []).filter((r: RunInstance) => {
            return isRefreshNeeded(r);
        }).length > 0
        if(shouldRefresh) {
            intervalId = setTimeout(() => setFilter(data => ({ ...data })), 1000);
        }
        return () => {
            !!intervalId && clearInterval(intervalId);
        }
    }, [instances, setFilter]);

    const handleStatusGo = (e: any, instance:RunInstance, status_key: string) => {
        e.stopPropagation();
        // here we set tab based on the status_key and actual status corresponding to the status_key
        if(status_key==='readiness_status'){
            if((instance[status_key] || {}).status === 'Succeeded') {
                history.push({
                    pathname: `/app/${props.app_id}/instance/${instance.id}/view/${props.active ? 'active' : 'archive'}`,
                    state: {tab: "input_view"}
                })
            }
            if((instance[status_key] || {}).status==='Failed') {
                history.push({
                        pathname: `/app/${props.app_id}/executions/${instance.import_process_id||instance.clone_process_id}/logs`,
                        state: {tab: "log_info"}
                    }
                )
            }
        }else if (status_key==='solving_status'){
            if((instance[status_key] || {}).status === 'Succeeded') {
                history.push({
                    pathname: `/app/${props.app_id}/instance/${instance.id}/view/${props.active ? 'active' : 'archive'}`,
                    state: {tab: "output_view"}
                })
            }
            if((instance[status_key] || {}).status==='Failed') {
                history.push({
                        pathname: `/app/${props.app_id}/executions/${instance.solve_process_id}/logs`,
                        state: {tab: "log_info"}
                    }
                )
            }
        }
    }

    const handleInstanceStatus = async(instance_id: string, is_locked: boolean) => {
        const response = await updateInstanceApi({
            instance_id: instance_id,
            is_locked: is_locked
        })
        if(response.status) {
            setFilter(data => ({ ...data }));
            setSelectedInstances([]);
            if(is_locked) {
                message.success(
                    t('instance.tip.lock_instance', {status: t('common.success')}),
                )
            }else{
                message.success(
                    t('instance.tip.unlock_instance', {status: t('common.success')}),
                )
            }
        } else {
            if(is_locked) {
                message.error(
                    t('instance.tip.lock_instance', {status: t('common.fail')}),
                )
            }else{
                message.error(
                    t('instance.tip.unlock_instance', {status: t('common.fail')}),
                )
            }
        }
    };

    const handleCellSave = async (run_instance, value) => {
        const response = await updateInstanceApi({
            instance_id: run_instance.id,
            name: value
        })
        if(response.status) {
            message.success(
                t('common.update_tip', {status: t('common.success')}),
            )
            setFilter({...filter});
        } else {
            message.error(
                t('common.update_tip', {status: t('common.fail')}),
            )
        }
    }

    const columns: ColumnsType<RunInstance> = [
        {
            title: t('instance.name'),
            key: 'name',
            width: 150,
            render: (r) => <EditCell object={r} valueField={"name"}
                disabled={r.is_locked || !props.active}
                handleCellSave={(value) => handleCellSave(r, value)}
                isLink={true}
                suffix={
                    <>
                        {
                            r.is_locked && <Tooltip placement="top" title={t("instance.locked")}>
                                <Image
                                    src={LockedIcon}
                                    preview={false}
                                    style={{ width: 15, height: 15, marginLeft: 5 }}
                                />
                            </Tooltip>
                        }
                        {
                            r.shared_time && <Popover placement="top" content={
                                <>
                                    <div>{t('instance.sharer')}：{r.sharer || '-'}</div>
                                    <div>{t('instance.shared_time')}：{viewUtils.utcToLocal(r.shared_time)}</div>
                                    <div>{t('instance.permission')}：{r.role === "ADMIN" ? t('instance.can_manage') : t('instance.only_visit')}</div>
                                </>
                            }>
                                <Badge color="green" count={t('instance.shared')}/>
                            </Popover>
                        }
                    </>
                }>
                <Typography.Paragraph className='table-link mb-0' ellipsis={{
                    rows: 1,
                    tooltip: {
                        title: r.name,
                        placement: "bottomLeft",
                        overlayClassName: 'description_tooltip',
                    }
                }}>
                    <Link
                        to={{
                            pathname: `/app/${props.app_id}/instance/${r.id}/view/${props.active ? 'active' : 'archive'}`,
                        }}
                    >
                        {r.name}
                    </Link>
                </Typography.Paragraph>
            </EditCell>
        },
        ...(props.show_session ? [{
            title: t('instance.session'),
            key: 'session_name',
            width: 100,
            render: (r) =>
                <Typography.Paragraph className='table-link mb-0' ellipsis={{
                    rows: 1,
                    tooltip: {
                        title: r.planning_session?.name || '',
                        placement: "bottomLeft",
                        overlayClassName: 'description_tooltip',
                    }
                }}>
                    <Link to={`/app/${props.app_id}/session/${r.planning_session?.id}/view/${props.active ? 'active' : 'archive'}`}>
                        {r.planning_session?.name || ''}
                    </Link>
                </Typography.Paragraph>
        }] : []),
        {
            title: t('instance.description'),
            key: 'description',
            width: 150,
            render: (r) => <Typography.Paragraph
                type="secondary"
                ellipsis={{
                    rows: 1,
                    tooltip: {
                        placement: "bottomLeft",
                        overlayClassName: 'description_tooltip',
                    }
                }}
                className='mb-0'>
                {r.description}
            </Typography.Paragraph>
        },
        {
            title: t('instance.created_at'),
            key: 'created_at',
            width: 150,
            sorter: true,
            defaultSortOrder: 'descend',
            showSorterTooltip: false,
            render: (r) => <span className='table-text-nowrap'>
                {viewUtils.utcToLocal(r.created_at)}
            </span>
        },
        {
            title: t('instance.readiness_status'),
            key: 'readiness_status',
            width: 120,
            render: (r) => r.readiness_status ? <ProcessStatusBtn
                status={r.readiness_status}
                showDuration={true}
                onClick={(e)=> handleStatusGo(e, r, 'readiness_status')}
            /> : null
        },
        {
            title: t('instance.solving_status'),
            key: 'solving_status',
            width: 120,
            render: (r) => r.solving_status ? <ProcessStatusBtn
                status={r.solving_status}
                showDuration={true}
                onClick={(e)=> handleStatusGo(e, r, 'solving_status')}
            /> : null
        },
        ...(props.show_list_actions ? [{
            title: t('instance.actions'),
            key: 'actions',
            width: 180,
            render: (r) => <div className="btn-group mr-2">
                {(r.role === RunInstanceMemberRole.ADMIN  || r.owner.includes("client.auth")) && <Button
                    onClick={() => handleInstanceStatus(r.id, !r.is_locked)}
                    type='link'
                    size='small'>
                    {r.is_locked ? t('instance.action.unlock_instance') : t('instance.action.lock_instance')}
                </Button>}
                <CopyToClipboard text={r.id}
                                 onCopy={() => {
                                    message.success(
                                        t('instance.tip.copy_instance_id', {status: t('common.success')})
                                    )
                                 }}>
                    <Button size='small' type='link'>
                        {t('instance.action.copy_instance_id')}
                    </Button>
                </CopyToClipboard>
                <Button size='small' type='link' onClick={() => handleCloneWithSession(r)}>
                    {t('instance.action.copy_instance')}
                </Button>
            </div>
        }] : [])
    ];

    const handleBatchArchive = async () => {
        if(selectedInstances.length > 0) {
            Modal.confirm({
                title: t('instance.confirm.archive'),
                async onOk() {
                    let [successCount, failCount] = [0, 0];
                    for(let item_index in selectedInstances){
                        const archive_response = await updateInstanceApi({
                            instance_id: selectedInstances[item_index],
                            active: false
                        })
                        !!archive_response.status ? successCount++ : failCount++;
                    }
                    if(successCount > 0) {
                        message.success(
                            t('instance.tip.archive', {
                                count: successCount,
                                status: t('common.success')
                            })
                        );
                    }
                    if(failCount > 0) {
                        message.error(
                            t('instance.tip.archive', {
                                count: failCount,
                                status: t('common.fail')
                            })
                        );
                    }
                    setSelectedInstances([]);
                    setFilter(data => ({ ...data }));
                }
            });
        }
    }

    const handleBatchUnArchive = async () => {
        if(selectedInstances.length > 0) {
            Modal.confirm({
                title: t('instance.confirm.unarchive'),
                async onOk() {
                    let [successCount, failCount] = [0, 0];
                    for(let item_index in selectedInstances){
                        const archive_response = await updateInstanceApi({
                            instance_id: selectedInstances[item_index],
                            active: true
                        })
                        !!archive_response.status ? successCount++ : failCount++;
                    }
                    if(successCount > 0) {
                        message.success(
                            t('instance.tip.unarchive', {
                                count: successCount,
                                status: t('common.success')
                            })
                        );
                    }
                    if(failCount > 0) {
                        message.error(
                            t('instance.tip.unarchive', {
                                count: failCount,
                                status: t('common.fail')
                            })
                        );
                    }
                    setSelectedInstances([]);
                    setFilter(data => ({ ...data }));
                }
            });
        }
    }

    const handleBatchDelete = async () => {
        if(selectedInstances.length > 0) {
            Modal.confirm({
                title: t('instance.confirm.delete'),
                async onOk() {
                    let [successCount, failCount] = [0, 0];
                    for(let item_index in selectedInstances){
                        const archive_response = await updateInstanceApi({
                            instance_id: selectedInstances[item_index],
                            is_deleted: true,
                        })
                        !!archive_response.status ? successCount++ : failCount++;
                    }
                    if(successCount > 0) {
                        message.success(
                            t('instance.tip.delete_instance', {
                                count: successCount,
                                status: t('common.success')
                            })
                        );
                    }
                    if(failCount > 0) {
                        message.error(
                            t('instance.tip.delete_instance', {
                                count: failCount,
                                status: t('common.fail')
                            })
                        );
                    }
                    setSelectedInstances([]);
                    setFilter(data => ({ ...data }));
                }
            });
        }
    }

    const handleCopyId = () => {
        if(selectedInstances.length === 1) {
            viewUtils.copyToClipboard(
                selectedInstances[0],
                () => {
                    message.success(
                        t('instance.tip.copy_instance_id', {status: t('common.success')})
                    )
                }
            );
        }
    }

    const instanceCloneModal = useMemo(() => (
        <RunInstanceCloneModal
            app_id={props.app_id}
            ref={instanceCloneModalRef}
            callback={() => {
                setSelectedInstances([]);
                setFilter(data => ({ ...data }));
            }}
        />
    ), [props.app_id])

    const instanceCompareModal = useMemo(() => (
        <RunInstanceCompareModal ref={instanceCompareModalRef}/>
    ), [])

    const handleClone = () => {
        if (!!selectedInstances[0]) {
            var instance:RunInstance= (instances.data || []).filter(
                (item: RunInstance) => item.id === selectedInstances[0]
            )[0];
            if (!instance || instance!.readiness_status?.status !== 'Succeeded') {
                message.error( t('instance.tip.cannot_copy') )
            } else {
                instanceCloneModalRef?.current?.show(instance, RunInstanceFormAction.Clone);
            }
        }
    }

    const handleCloneWithSession = (instance: RunInstance) => {
        if (!instance || instance!.readiness_status?.status !== 'Succeeded') {
            message.error( t('instance.tip.cannot_copy') )
        } else {
            instanceCloneModalRef?.current?.show(instance, RunInstanceFormAction.CloneWithSession);
        };
    }

    const handleCompare = () => {
        const data = (instances.data || []).filter(
            (item: RunInstance) =>
                selectedInstances.includes(item.id) &&
                item.readiness_status?.status === 'Succeeded'
        );
        if(selectedInstances.length > 2) {
            message.error( t('instance.tip.compare_count_error_overflow') )
        } else if (selectedInstances.length < 2) {
            message.error( t('instance.tip.compare_count_error_lack') )
        } else if (selectedInstances.length !== data.length) {
            message.error( t('instance.tip.compare_count_error_status') )
        } else {
            instanceCompareModalRef?.current?.show(data);
        }
    }

    const actionMenu = () => (
        <Menu style={{ textAlign: 'center' }}>
            {
                props.clone &&
                    !!props.apps[props.app_id] &&
                    (props.apps[props.app_id].app_manifest?.pipelines?.CLONE || []).length > 0 && <Menu.Item
                    key='copy'
                    disabled={selectedInstances.length !== 1}
                    onClick={handleClone}>
                    {t('instance.action.copy_instance')}
                </Menu.Item>
            }
            {
                props.archive && <Menu.Item key='compare' onClick={handleCompare}>
                    {t('instance.action.compare')}
                </Menu.Item>
            }
            <Menu.Item key='delete' disabled={selectedInstances.length < 1} onClick={handleBatchDelete}>
                {t('instance.action.delete_instance')}
            </Menu.Item>
            {
                !config.IsSimpleLayout &&
                props.archive && <Menu.Item key='archive' disabled={selectedInstances.length < 1} onClick={handleBatchArchive}>
                    {t('instance.action.archive')}
                </Menu.Item>
            }
            {
                props.unarchive && <Menu.Item key='unarchive' disabled={selectedInstances.length < 1} onClick={handleBatchUnArchive}>
                    {t('instance.action.unarchive')}
                </Menu.Item>
            }
            <Menu.Item key='copyId' disabled={selectedInstances?.length !== 1} onClick={handleCopyId}>
                {t('instance.action.copy_instance_id')}
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            {instanceCloneModal}
            {instanceCompareModal}
            {
                !props.show_list_actions && <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-2 mb-3">
                    {
                        props.search ? <Input
                            value={filter?.search_key_words}
                            className='list-search-input'
                            placeholder={t('common.keyword_search_run_instances')}
                            onChange={handleChange}
                            suffix={
                                <Image
                                    src={HeaderSearchIcon}
                                    preview={false}
                                    style={{ width: 15, height: 15 }}
                                />
                            }
                        /> : <div></div>
                    }
                    <div className="d-flex justify-content-end flex-wrap flex-md-nowrap align-items-center">
                        {
                            selectedInstances.length === 1 && currentInstance && <button
                                type="button"
                                onClick={() => handleInstanceStatus(currentInstance.id, !currentInstance.is_locked)}
                                className={`btn ${'btn-outline-secondary'}`}>
                                {currentInstance.is_locked ? t('instance.action.unlock_instance') : t('instance.action.lock_instance')}
                            </button>
                        }
                        {
                            props.instance_create && <RunInstanceCreateButton
                                app_id={props.app_id}
                                session_id={props.session_id}
                                successCallback={() => setFilter(data => ({ ...data })) }
                            />
                        }
                        {
                            !props.show_list_actions && <Dropdown placement="bottom" dropdownRender={actionMenu}>
                                <button
                                    type="button"
                                    className="btn btn-outline-secondary"
                                    style={{marginLeft: '0.5em', paddingLeft: 9, paddingRight: 9}}>
                                    <EllipsisOutlined style={{fontSize: '1.4em'}} />
                                </button>
                            </Dropdown>
                        }
                    </div>
                </div>
            }
            <div className='table-responsive bg-white'>
                <Table
                    tableLayout='auto'
                    {...((props.archive || props.unarchive) ? {
                        rowSelection: {
                            selectedRowKeys: selectedInstances,
                            onChange: selectedRowKeys => {
                              setSelectedInstances(selectedRowKeys)
                            },
                            columnWidth: 50,
                            fixed: true
                        }
                    } : null)}
                    size={props.smallSize ? 'small': 'large'}
                    scroll={{ x: props.show_session ? 790 : 870, scrollToFirstRowOnChange: true }}
                    pagination={false}
                    rowKey={r => r.id}
                    columns={columns}
                    dataSource={instances.data}
                    onChange={handleTableChange}
                />
                <Pagination
                    size={props.smallSize ? 'small': 'default'}
                    showQuickJumper
                    showTotal={(total) => t("common.total", { count: total })}
                    current={filter.page}
                    pageSize={filter.page_size}
                    pageSizeOptions={[10, 20, 50]}
                    total={instances.total}
                    onChange={handlePageChange}
                    showSizeChanger={!props.smallSize}
                    hideOnSinglePage={!instances.data?.length || !!props.smallSize}
                    style={{textAlign: 'right', justifyContent: 'flex-end', margin: '1em 0'}}
                />
            </div>
        </>
    )
};

const mapStateToProps = (store) => ({
  apps: store.account.apps,
  current_workspace_id: store.account.current_workspace?.id,
  need_refresh: store.account.refresh,
})

export default connect(mapStateToProps, {})(RunInstanceList);
