import {
  Badge,
  Button,
  List,
  Modal,
  Popover,
  Space,
  Tabs,
  TabsProps,
} from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { ClockCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import {
  IMessage,
} from "@novu/headless";
import viewUtils from "../../../utils/viewUtils";
import { useTranslation } from "react-i18next";
import { NotificationCategory } from "../../../interfaces/notification";
import NotificationIcon from "../../../common/images/icons/NotificationIcon";
import useNovu from "../../../utils/useNovu";

let modalOpen = false;

const Novu = (props: any) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [datalist, setDatalist] = useState<{
    data?: IMessage[];
    total: number;
  }>({
    data: undefined,
    total: 0,
  });
  const [filter, setFilter] = useState<{
    page: number;
    limit: number;
    category: NotificationCategory;
  }>({
    page: 0,
    limit: 1000,
    category: NotificationCategory.APP,
  });
  const novu = useNovu();
  const [unreadCount, setUnreadCount] = useState(0);

  useEffect(() => {
    if(props.current_app_id === null || props.account_info === null) {
      return;
    }

    if (history.location.pathname.includes("/app") && !props.current_app_id) {
      return;
    }

    novu.initSession({
      onInited: () => {
        updateUnreadCount();
        getSystemUpdateMessages();
      },
      onUnreadCountChanged: () => {
        updateUnreadCount();
        getSystemUpdateMessages();
      },
    });

    return () => {
      if (novu.socket.current) {
        novu.socket.current.disconnect();
      }
    };
  }, [props.account_info, props.current_app_id]);

  useEffect(() => {
    updateMessages();
    updateUnreadCount();
  }, [props.current_app_id, filter]);

  const updateMessages = async () => {
    if (!novu.isAvailable()) {
      return;
    }

    if (history.location.pathname.includes("/app") && !props.current_app_id) {
      return;
    }

    setDatalist({ data: undefined, total: 0 });
    const data = await novu.getMessages({
      ...filter,
      payload:
        filter.category === NotificationCategory.PLATFORM
          ? { category: filter.category }
          : {
              category: filter.category,
              workspace_id: props.current_workspace_id,
              app_id: props.current_app_id,
            },
    });
    setDatalist({
      data: data.data,
      total: data.totalCount,
    });
  };

  const getSystemUpdateMessages = async () => {
    const data = await novu.getMessages({
      payload: {
        id: "system_update",
      },
    });
    if (data.totalCount > 0 && !data.data[0].read) {
      const message = data.data[0];
      await novu.markMessaegsAsRead(message._id);

      if (!modalOpen) {
        modalOpen = true;
        Modal.info({
          icon: null,
          title: t("notification.popover.release_note_title"),
          content: (
            <div style={{ height: 360, overflow: "hidden", marginTop: '2.4rem', marginBottom: '-2.4rem' }}>
              <iframe
                id="iframe"
                src={message.payload.in_app_redirect_url}
                style={{ marginTop: '-5rem', width: '100%', height: "calc(100% + 8rem)" }}
                  // onLoad={() => {
                  //   setTimeout(() => {
                  //     const iframe = document.getElementById("iframe");
                  //     const innerDoc =
                  //       iframe.contentDocument || iframe.contentWindow.document;
                  //     const footers = Array.from(
                  //       innerDoc.getElementsByClassName("md-footer")
                  //     );
                  //     for (let i = 0; i < footers.length; i++) {
                  //       const footer = footers[i];
                  //       if (footer) {
                  //         footer.parentNode?.removeChild(footer);
                  //       }
                  //     }
                  //   }, 1000);
                  // }}
              />
            </div>
          ),
          width: 1200,
          onOk: () => {modalOpen = false}
        });
      }
    }
  };

  const updateUnreadCount = async () => {
    if (!novu.isAvailable()) {
      return;
    }

    if (history.location.pathname.includes("/app") && !props.current_app_id) {
      return;
    }

    const data = await novu.getMessageCount({
      read: false,
      payload: Object.values(NotificationCategory).map(value => ({
        category: value
      })),
    });
    setUnreadCount(data?.data?.count);
  };

  const handleCategoryChange = (key: string) => {
    setFilter({
      ...filter,
      page: 0,
      category: key as NotificationCategory,
    });
  };

  const handleClickReadAll = async () => {
    if (!novu.isAvailable()) {
      return;
    }
    await novu.markAllMessagesAsRead();
    updateMessages();
  };

  const handleClickNotification = async (notification: IMessage) => {
    if (!novu.isAvailable()) {
      return;
    }
    const data = await novu.markMessaegsAsRead(notification._id);
    notification.read = data.data[0].read;
    setDatalist({ ...datalist });

    if (notification.payload.in_app_redirect_url) {
      let url;
      if (
        (notification.payload.in_app_redirect_url as string).startsWith("http")
      ) {
        url = `${notification.payload.in_app_redirect_url}`;
      } else {
        url = `${window.location.origin}${notification.payload.in_app_redirect_url}`;
      }
      window.open(url, "_blank")?.focus();
    }
  };

  const handleClickHistory = () => {
    history.push({
      pathname: `/app/${props.current_app_id}/notification`,
    });
  };

  const handleOpenChange = (open: boolean) => {
    if (open) {
      setFilter({ ...filter });
    }
  };

  return (
    <Popover
      onOpenChange={handleOpenChange}
      content={
        <div className="layout-content-box">
          <div className="notification-tab">
            <Tabs
              style={{ flex: 1 }}
              renderTabBar={
                ((props, DefaultTabBar) => (
                  <DefaultTabBar {...props} className="notification-type-tab" />
                )) as TabsProps["renderTabBar"]
              }
              items={
                [
                  {
                    key: NotificationCategory.APP,
                    label: t("notification.popover.app"),
                  },
                  {
                    key: NotificationCategory.WORKSPACE,
                    label: t("notification.popover.workspace"),
                  },
                  {
                    key: NotificationCategory.PLATFORM,
                    label: t("notification.popover.platform"),
                  },
                ] as TabsProps["items"]
              }
              tabBarExtraContent={
                <div
                  style={{ color: "#2a61ff", cursor: "pointer" }}
                  onClick={handleClickReadAll}
                >
                  {t("notification.popover.read_all")}
                </div>
              }
              onChange={handleCategoryChange}
            />
            <div
              style={{
                width: "40em",
                height: "20em",
                overflowX: "hidden",
                overflowY: "auto",
              }}
            >
              <List
                pagination={false}
                loading={!datalist.data}
                dataSource={datalist.data}
                renderItem={(notification) => (
                  <List.Item
                    style={{ marginRight: "1em", cursor: "pointer" }}
                    onClick={() => handleClickNotification(notification)}
                  >
                    <List.Item.Meta
                      title={
                        <Space size="small">
                          {notification.read ? null : <Badge dot></Badge>}
                          {notification.payload.in_app_title as string}
                        </Space>
                      }
                      description={
                        <div style={{ cursor: "pointer" }}>
                          {notification.payload.in_app_content as string}
                        </div>
                      }
                    />
                    <span>
                      {viewUtils.prettifyDatetime(notification.createdAt)}
                    </span>
                  </List.Item>
                )}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                paddingRight: "1em",
                marginTop: "1em",
              }}
            >
              {props.current_app_id && (
                <ClockCircleOutlined onClick={handleClickHistory} />
              )}
            </div>
          </div>
        </div>
      }
    >
      <Button size="large" type="text" className="header-button">
        <Badge count={unreadCount} showZero size="small">
          <NotificationIcon
            style={{ fontSize: 20, width: 20, height: 20, color: "#8f95b2" }}
          />
        </Badge>
      </Button>
    </Popover>
  );
};

const mapStateToProps = (store: any) => ({
  account_info: store.account.account_info,
  current_workspace_id: store.account.current_workspace?.id,
  current_app_id: store.account.current_app_id,
});

export default connect(mapStateToProps, {})(Novu);
